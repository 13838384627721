import { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Frame from './common/Frame';
import LoginPage from './login/LoginPage';
import useToken from './login/useToken';


const THEME = createMuiTheme({
  typography: {
   fontFamily: `"Calibri", sans-serif`,
   fontSize: 14,
   fontWeightLight: 300,
   fontWeightRegular: 400,
   fontWeightMedium: 500,
  },
});

function App() {
  const { token, setToken } = useToken();
  const [stateSnackbar, setSnackbar] = useState({
		openSnackbar: false,
		vertical: 'top',
		horizontal: 'center',
	});
  const [currentUser, setCurrentUser] = useState('');  

  if(!token) {
    return <LoginPage
      setToken={setToken}
      stateSnackbar={stateSnackbar}
      setSnackbar={setSnackbar}
      setCurrentUser={setCurrentUser}
    />
  }

  return (
    <div>
      <MuiThemeProvider theme={THEME}>
        <BrowserRouter>
          <Frame
            stateSnackbar={stateSnackbar}
            setSnackbar={setSnackbar}
            currentUser={currentUser}
          />
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
