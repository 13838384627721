import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
}));

export default function CommentForm(props) {
    const [comment, setComment] = useState('');
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    function handleChange(e) {
        setComment(e.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return(
    <div>
        <Tooltip title="Ajouter un commentaire">
            <IconButton aria-label="add">
                <AddCircleOutlineRoundedIcon onClick={handleClickOpen} />
            </IconButton>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <DialogTitle id="form-dialog-title" onClose={handleClose} >
                Commentaire:
            </DialogTitle>
            <DialogContent>
                <form id="formComment" onSubmit={(e) => {
                    e.preventDefault();
                    comment.trim() !== '' ? props.addComment(comment) : handleClose();
                    setComment('');
                }}>
                    <TextField
                        className={classes.textField}
                        autoFocus
                        margin="dense"
                        id="new-comment-input"
                        type="text"
                        multiline
                        rows={14}
                        fullWidth
                        value={comment}
                        onChange={handleChange}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    type="submit"
                    form="formComment" 
                    onClick={handleClose}
                >
                    Enregistrer
                </Button>
            </DialogActions>
      </Dialog>
    </div>
    );
}
