import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import TaskForm from './TaskForm';
import CommentForm from './CommentForm';
import NewsFeed from './NewsFeed';
// import AddUsersForm from '../common/AddUsersForm';
import { urlCommentTask, urlShipmentTaskCommentCount } from '../common/Constants'; //, urlUsers
// import UploadFiles from '../common/upload-files.component';
// import UploadFilesTask from '../common/UploadFilesTask';
// import Feb16 from '../common/Feb16';
// import { CountCommentsTasksContext } from '../common/tracking-context';


const useStyles = makeStyles((theme) => ({
  tooltip: {
      color: "#000000",
  },
  greyLight: {
      backgroundColor: "#f5f5f5",
      fontSize: "0.7rem",
      margin: 0,
      padding: theme.spacing(1),
  },
  orangeLight: {
      backgroundColor: "#ffe0b2",
      fontSize: "0.7rem",
      margin: 0,
      padding: theme.spacing(1),
  },
  greenLight: {
      backgroundColor: "#c8e6c9",
      fontSize: "0.7rem",
      margin: 0,
      padding: theme.spacing(1),
  },
  btnOuvrir: {
    textTransform: "none",
    fontSize: ".8rem",
    width: '90%',
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  headers1: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.6),
    color: "grey",
  },
  headers2: {
    paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
  },
  bodyHeight: {
    minHeight: '60vh',
  },
  showMoreInfo: {
    color: 'grey',
    backgroundColor: '#f9f9ff',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="subtitle2">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function OuvrirPopUp(props) {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const [open, setOpen] = useState(false);
  const [show,setShow] = useState(false);
  const [refreshKey,setRefreshKey] = useState(0);
  const [countCommentsTasks,setCountCommentsTasks] = useState([]);

  const { trackingId,
    trackingNumber,
    pickupContact,
    deliveriContact,
    pickupAddress,
    deliveriAddress,
    pickupPhone,
    deliveriPhone,
    pickupEmail,
    deliveriEmail
  } = props;
  
  const username = localStorage.getItem('username');
  const currentUser = username === 'ikompar' ? 1 : 0;

  useEffect(() => {
    fetch(`${urlCommentTask}/get_shipment?shipment_id=${trackingId}&sort=-id`)
      .then(response => response.json())
      .then(json => setComments(json))
      .catch(error => console.error(error))
  }, [trackingId, refreshKey]);

  useEffect(() => {
    fetch(urlShipmentTaskCommentCount)
      .then(res => res.json())
      .then(json => setCountCommentsTasks(json))
      .catch(err => console.error(err))
  }, []);

  function postCommentTask(args) {
    fetch(urlCommentTask, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(args)
    })
    .then(response => {setRefreshKey(oldKey => oldKey + 1);})
    .catch(error => console.error(error))
  };

  function editCommentTask(id, taskStatus, newCommentTask, personName, dueTo) {
    fetch(`${urlCommentTask}/${id}`, {
      method: 'PUT', // GET, POST, PUT, DELETE
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({due_date: dueTo, comment: newCommentTask, to_users: personName, task_status: taskStatus, updated_by: currentUser})
    })
    .then(response => {setRefreshKey(oldKey => oldKey + 1);})
    .catch(error => console.error(error))
  };
  
  function deleteComment(id) {
    fetch(`${urlCommentTask}/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({is_actif: 0})
    })
    .then(response => {setRefreshKey(oldKey => oldKey + 1);})
    .catch(error => console.error(error))
  };

  // function deleteComment(args) {
    // fetch(`${url}/${args}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    // .then(response => {setRefreshKey(oldKey => oldKey + 1);})
    // .catch(error => console.error(error))
  // };

  // Auto refresh at a given time
  // setInterval(function(){ setRefreshKey(value => value + 1); }, 3000);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickShow = () => {
    setShow(!show);
  };

  const today = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()).toString().padStart(2, '0');
  
  const yesterday = (new Date().getFullYear()).toString()+'-'+(new Date().getMonth()+1).toString().padStart(2, '0')+'-'+(new Date().getDate()-1).toString().padStart(2, '0');

  const forNewsFeed = comments.reduce((myResult, item) => {
    let current_date = (new Date(item.created_at*1000).getFullYear()).toString()+'-'+(new Date(item.created_at*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(item.created_at*1000).getDate()).toString().padStart(2, '0');
    if (!myResult[current_date]) myResult[current_date] = [];
    myResult[current_date].push(item);
    return myResult;
  }, {});

  const newsFeedList = Object.keys(forNewsFeed).map(groupByDate => {
    return <div key={groupByDate}>
      <h3 style={{textAlign: 'center'}}>{groupByDate === today ? 'Aujourd’hui' : groupByDate === yesterday ? 'Hier' : groupByDate}</h3>
      {forNewsFeed[groupByDate].map(item => <NewsFeed
          id={item.id}
          comment={item.comment}
          toUsers={item.to_users !== null ? item.to_users : ''}
          isTask={item.is_task}
          taskStatus={item.task_status}
          createdBy={item.created_by}
          updatedBy={item.updated_by}
          createdDate={item.created_at}
          updatedDate={item.updated_at}
          dueDate={item.due_date}
          editCommentTask={editCommentTask}
          deleteComment={deleteComment}
          key={item.id}
        />)}
      </div>;
  });

  function addTask(task, toUsers, dueDate) {
    const dueDateConvert = Math.trunc(new Date(dueDate).getTime()/1000);
    const newTask = {shipment_id: trackingId, due_date: dueDateConvert, comment: task, is_task: 1, to_users: toUsers, created_by: currentUser};
    postCommentTask(newTask);
  }

  function addComment(comment) {
    const newComment = {shipment_id: trackingId, comment: comment, created_by: currentUser};
    postCommentTask(newComment);
  }

  const showMoreInfo = (
    <Grid
      container
      direction="row"
      justify="space-around"
      alignItems="flex-start"
      className={classes.showMoreInfo}
    >
      <Grid item xs={6}>
        <span><b>Expéditeur:</b></span><br />
        <span>{pickupContact}</span><br />
        <span>{pickupAddress}</span><br />
        <span>{pickupPhone}</span><br />
        <span>{pickupEmail}</span>
      </Grid>
      <Grid item xs={6}>
        <span><b>Destinataire:</b></span><br />
        <span>{deliveriContact}</span><br />
        <span>{deliveriAddress}</span><br />
        <span>{deliveriPhone}</span><br />
        <span>{deliveriEmail}</span>
      </Grid>
    </Grid>
  );

  // static contextType = CountCommentsTasksContext;
  // countCommentsTasks => (countCommentsTasks[`${trackingId}`] !== undefined ? countCommentsTasks[`${trackingId}`]['nbr_tasks_1'] : 0)

  return (
    <div>
      {/* <CountCommentsTasksContext.Consumer> */}
      
      <Button
        variant="outlined"
        size="large"
        className={classes.btnOuvrir}
        onClick={handleClickOpen}
      >
        Tâches à faire ({countCommentsTasks[`${trackingId}`] !== undefined ? countCommentsTasks[`${trackingId}`]['nbr_tasks_1'] : 0}){<br />}
        Mails non lus (){<br />}
        Commentaires ({countCommentsTasks[`${trackingId}`] !== undefined ? countCommentsTasks[`${trackingId}`]['nbr_comments'] : 0})
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <Typography className={classes.headers1}>
          <Button
            className={classes.buttonLowerCase}
            size="small"
            onClick={handleClickShow}
          >
            N° suivi: {trackingNumber.replace(/<br\/>/g, ", ")}
          </Button><br />
        </Typography>
        {show ? showMoreInfo : null}
        <DialogTitle id="customized-dialog-title">
            <Grid
              container 
              spacing={1}
              direction="row"
              justify="space-around"
              alignItems="flex-start"
              align="center"
              className={classes.tooltip}
            >
                <Grid item xs={4}>Ecrire un email</Grid>
                <Grid item xs={4}>Ajouter une tâche</Grid>
                <Grid item xs={4}>Ajouter un commentaire</Grid>
                <Grid item xs={4}>
                  {/* <AddUsersForm /> */}
                  {/* <UploadFiles /> */}
                  {/* <UploadFilesTask /> */}
                  {/* <Feb16 trackingId={trackingId} /> */}
                  <Tooltip title="Ecrire un email"disabled>
                      <IconButton aria-label="add">
                        <AddCircleOutlineRoundedIcon />
                      </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={4}>
                  <TaskForm
                    addTask={addTask}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CommentForm addComment={addComment} />
                </Grid>
            </Grid>
          <Divider />
          <Typography className={classes.headers2}>
            Tâches à faire ({countCommentsTasks[`${trackingId}`] !== undefined ? countCommentsTasks[`${trackingId}`]['nbr_tasks_1'] : 0}) Tâches en cours ({countCommentsTasks[`${trackingId}`] !== undefined ? countCommentsTasks[`${trackingId}`]['nbr_tasks_2'] : 0})
          </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.bodyHeight}>
            {newsFeedList}
          </div>
        </DialogContent>
      </Dialog>
      {/* </CountCommentsTasksContext.Consumer> */}
    </div>
  );
}
