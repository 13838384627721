import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
    root: {
        margin: 'auto',
    },
    dialogTitle: {
        marginTop: theme.spacing(1),
    },
    formGroup: {
        margin: 'auto',
        padding: theme.spacing(2),
        width: 'fit-content',
    },
    btn: {
      // marginLeft: theme.spacing(3),
      // paddingLeft: theme.spacing(3),
      // margin: theme.spacing(-3),
      // marginBottom: theme.spacing(-2.1),
      // padding: theme.spacing(-3),
      color: theme.palette.grey[700],
    },
}));


const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: "center",
  },
}))(MuiDialogActions);

export default function TrackingPopUpDelete() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: false,
    checkedC: false,
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <DeleteOutlinedIcon
          fontSize="large"
          onClick={handleClickOpen}
          className={classes.btn}
        />
        <Dialog fullWidth={fullWidth} maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
            <div className={classes.root}>
            <DialogTitle id="customized-dialog-title" className={classes.dialogTitle} onClose={handleClose}>
                Que veux-tu faire Jehona ?
            </DialogTitle>
            <Divider />
            <FormGroup className={classes.formGroup}>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                        color="primary"
                    />
                    }
                    label="Chacher le tracking"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={state.checkedB}
                        onChange={handleChange}
                        name="checkedB"
                        color="primary"
                        disabled
                    />
                    }
                    label="Supprimer l’envoi et la facture"
                />
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={state.checkedC}
                        onChange={handleChange}
                        name="checkedC"
                        color="primary"
                        disabled
                    />
                    }
                    label="Créer une note de crédit"
                />
            </FormGroup>
            <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Valider
                </Button>
            </DialogActions>
            </div>
        </Dialog>
    </div>
  );
}
