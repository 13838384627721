import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { urlUsers } from '../common/Constants';


const useStyles = makeStyles((theme) => ({
    greyLight: {
        backgroundColor: "#f5f5f5",
        fontSize: "0.7rem",
        margin: 0,
        padding: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
    orangeLight: {
        backgroundColor: "#ffe0b2",
        fontSize: "0.7rem",
        margin: 0,
        padding: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
    greenLight: {
        backgroundColor: "#c8e6c9",
        fontSize: "0.7rem",
        margin: 0,
        padding: theme.spacing(1),
        // marginBottom: theme.spacing(1),
    },
    toRightSide: {
        float: "right",
    },
    taskStatus0: {
        textTransform: "none",
        background: "#ff9a9a",
    },
    taskStatus1: {
        textTransform: "none",
        background: "#ffff59",
    },
    taskStatus2: {
        textTransform: "none",
        background: "#94eb94",
    },
    noneTextTransform: {
        textTransform: "none",
    },
    toWho: {
        width: "80%",
    },
    dateTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateTime: {
        width: 200,
    },
    formControl: {
        // marginTop: theme.spacing(2),
        // marginLeft: theme.spacing(3),
        // marginRight: theme.spacing(2),
        // width: "95%",
    },
    editTaskLabel: {
        
    },
    spacingBetween: {
        marginBottom: theme.spacing(1),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//     {
//         "id": 1,
//         "username": "iKompar"
//     },
//     {
//         "id": 2,
//         "username": "Robert"
//     },
//     {
//         "id": 3,
//         "username": "Hans"
//     }
// ];

export default function NewsFeed(props) {
    const classes = useStyles();
    const [isEditing, setEditing] = useState(false);
    const { id,
        comment,
        toUsers,
        isTask,
        taskStatus,
        createdBy,
        updatedBy,
        createdDate,
        updatedDate,
        dueDate,
        editCommentTask,
        deleteComment
    } = props;
    const [newComment, setNewComment] = useState(comment);
    const [personName, setPersonName] = useState(toUsers.split(','));
    const [dueTo, setDueTo] = useState(dueDate !== null ? dueDate : Math.trunc(new Date().getTime()/1000));
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetch(urlUsers)
            .then(response => response.json())
            .then(json => setUsers(json))
            .catch(error => console.error(error))
    }, []);
          
    function handleChange(e) {
        setNewComment(e.target.value);
    };

    const handleChange2 = (event) => {
        setPersonName(event.target.value);
    };
    
    function handleChange3(e) {
        setDueTo(Math.trunc(new Date(e.target.value).getTime()/1000));
    }

    const ifTaskEditing = (<Grid
            container
            direction="row"
            justify="space-around"
            alignItems="flex-start"
            className={classes.formControl}
        >
            <Grid item xs={1}>
                <p className={classes.editTaskLabel}><b>(Tâche):</b></p>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
                <FormControl className={classes.toWho}>
                    <InputLabel id="task-mutiple-checkbox-label" className={classes.dropDown}>Tâche assignée à:</InputLabel>
                    <Select
                        labelId="users-mutiple-checkbox-label"
                        id="users-mutiple-checkbox2"
                        multiple
                        value={personName}
                        onChange={handleChange2}
                        input={<Input />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {users.map((item) => (
                            <MenuItem key={item.username} value={item.username}>
                                <Checkbox checked={personName.indexOf(item.username) > -1} />
                                <ListItemText primary={item.username} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5}>
                <form className={classes.dateTimeContainer} noValidate>
                    <TextField
                        id="datetime-task"
                        label="Due to:"
                        type="datetime-local"
                        className={classes.dateTime}
                        InputLabelProps={{
                        shrink: true,
                        }}
                        defaultValue={((new Date(dueTo*1000)).getFullYear().toString()+'-'+(new Date(dueTo*1000).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo*1000)).getDate().toString().padStart(2, '0')+'T'+(new Date(dueTo*1000)).getHours().toString().padStart(2, '0')+':'+(new Date(dueTo*1000)).getMinutes().toString().padStart(2, '0'))}
                        onChange={handleChange3}
                    />
                </form>
            </Grid><br /><br />
        </Grid>
    );

    const editing = (
        <div className={isTask === 0 ? classes.greenLight : classes.orangeLight}>
            <form id="formEditCommentTask" onSubmit={(e) => {
                e.preventDefault();
                editCommentTask(id, taskStatus, newComment, isTask === 1 ? personName.toString() : null, isTask === 1 ? dueTo : null);
                setNewComment(newComment);
                setEditing(false);
            }}>
                <span>{isTask === 0 ? <b>(Commentaire):</b>: ifTaskEditing}<TextField
                        autoFocus
                        multiline
                        fullWidth
                        type="text"
                        margin="dense"
                        value={newComment}
                        onChange={handleChange}
                /></span>
            </form>
            <Button
                className={classes.noneTextTransform}
                size="small"
                onClick={() => setEditing(false)}
            >
                Cancel
            </Button>
            <Button
                className={classes.noneTextTransform}
                size="small"
                type="submit"
                form="formEditCommentTask"
            >
                Save
            </Button>
        </div>
    );

    const ifTask = (<span>
        <Button
            className={taskStatus === 1 ? classes.taskStatus0 : classes.noneTextTransform}
            size="small"
            onClick={() => editCommentTask(id, 1, comment)}
        >
            à faire
        </Button>
        <Button
            className={taskStatus === 2 ? classes.taskStatus1 : classes.noneTextTransform}
            size="small"
            onClick={() => editCommentTask(id, 2, comment)}
        >
            en cours
        </Button>
        <Button
            className={taskStatus === 3 ? classes.taskStatus2 : classes.noneTextTransform}
            size="small"
            onClick={() => editCommentTask(id, 3, comment)}
        >
            terminé
        </Button>
    </span>);

    const ifUpdate = <p>Dernière modification à: <b>{updatedBy === 1 ? 'ikompar' : 'admin'} ({(new Date(updatedDate*1000).getFullYear()).toString()}-{(new Date(updatedDate*1000).getMonth()+1).toString().padStart(2, '0')}-{(new Date(updatedDate*1000).getDate()).toString().padStart(2, '0')} {(new Date(updatedDate*1000).getHours()).toString().padStart(2, '0')}:{(new Date(updatedDate*1000).getMinutes()).toString().padStart(2, '0')})</b></p>;

    const display = (
        <div className={isTask === 0 ? classes.greenLight : classes.orangeLight}>
            <p>{isTask === 0 ? <b>(Commentaire):</b>: <b>(Tâche): (Due to: {(new Date(dueDate*1000).getFullYear()).toString()}-{(new Date(dueDate*1000).getMonth()+1).toString().padStart(2, '0')}-{(new Date(dueDate*1000).getDate()).toString().padStart(2, '0')} {(new Date(dueDate*1000).getHours()).toString().padStart(2, '0')}:{(new Date(dueDate*1000).getMinutes()).toString().padStart(2, '0')})</b>} {comment}</p>
            {isTask === 1 && <p>Attribué à : <b>{toUsers}</b></p>}
            <p>Ajouté par: <b>{createdBy === 1 ? 'ikompar' : 'admin'}</b><span className={classes.toRightSide}>({(new Date(createdDate*1000).getHours()).toString().padStart(2, '0')}:{(new Date(createdDate*1000).getMinutes()).toString().padStart(2, '0')})</span></p>
            {createdDate !== updatedDate ? ifUpdate : null}
            {isTask === 1 && ifTask}
            <span className={isTask === 1 ? classes.toRightSide : null}>
                <Button
                    className={classes.noneTextTransform}
                    size="small"
                    onClick={() => setEditing(true)}
                >
                    Edit
                </Button>
                <Button
                    className={classes.noneTextTransform}
                    size="small"
                    onClick={() => deleteComment(id)}
                >
                    Delete
                </Button>
            </span>
        </div>
    );

    return <div className="newsFeed">{isEditing ? editing : display}<Divider light className={classes.spacingBetween} /></div>;
}