import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FuseAnimate from '../fuse/FuseAnimate';
import useForm from '../fuse/useForm';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { urlLogin } from "../common/Constants";


const useStyles = makeStyles(theme => ({
	root: {
        '& > *': {
            margin: theme.spacing(3),
		},
		height: '100vh',
        background: "radial-gradient(rgb(9, 17, 24) 0%, #122230 80%)",
		color: theme.palette.primary.contrastText,
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
		flexShrink: 0,
		alignItems: 'center',
		padding: '2.8rem',
		paddingTop: '4.6rem',
	},
	div2: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
	},
	card: {
		width: '100%',
		maxWidth: '22.4rem',
	},
	cardContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '2.8rem',
	},
	headType: {
		marginTop: '.6rem',
		marginBottom: '2.6rem',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		width: '100%',
	},
	usernamePassword: {
		marginBottom: '1.6rem',
	},
	checkbox: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	btn: {
		width: '14.4rem',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: '.6rem',
		marginBottom: '.6rem',

	},
}));

async function loginUser(credentials) {
	return fetch(urlLogin, {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json'
	  },
	  body: JSON.stringify(credentials)
	})
	.then(data => data.json())
  }

function LoginPage({ setToken, setSnackbar, setCurrentUser }) {
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	const [incorrectLogin, setIncorrectLogin] = useState(false);
	const classes = useStyles();

	const { form, handleChange, resetForm } = useForm({
		username: '',
		password: '',
		remember: true
	});

	const handleSubmit = async e => {
		e.preventDefault();
		const token = await loginUser({
		  username,
		  password
		});
		setToken(token);
		JSON.stringify(token).slice(2, 5) === 'non' && setIncorrectLogin(true);
		JSON.stringify(token).slice(2, 5) === 'tok' && setSnackbar({ openSnackbar: true, vertical: 'bottom', horizontal: 'left' });
		JSON.stringify(token).slice(2, 5) === 'tok' && setCurrentUser(username);
		JSON.stringify(token).slice(2, 5) === 'tok' && localStorage.setItem('username', username);
		resetForm();
	}

	return (
		<div className={classes.root}>
			<div className={classes.div2}>
				<FuseAnimate animation="transition.expandIn">
					<Card className={classes.card}>
						<CardContent className={classes.cardContent}>
							<Typography variant="h6" className={classes.headType}>
								LOGIN TO YOUR ACCOUNT
							</Typography>
							<form
								name="loginForm"
								noValidate
								className={classes.form}
								onSubmit={handleSubmit}
							>
								<TextField
									className={classes.usernamePassword}
									label="Username"
									autoFocus
									type="text"
									name="username"
									onChange={e => setUsername(e.target.value)}
									size="small"
									variant="outlined"
									required
									fullWidth
									error={incorrectLogin}
								/>
								<TextField
									className={classes.usernamePassword}
									label="Password"
									type="password"
									name="password"
									onChange={e => setPassword(e.target.value)}
									size="small"
									variant="outlined"
									required
									fullWidth
									error={incorrectLogin}
									helperText={incorrectLogin ? "Incorrect username or password." : ""}
								/>
								<div className={classes.checkbox}>
									<FormControl>
										<FormControlLabel
											control={
												<Checkbox
													name="remember"
													checked={form.remember}
													onChange={handleChange}
												/>
											}
											label="Remember Me"
										/>
									</FormControl>
								</div>
								<Button
									variant="contained"
									color="primary"
									className={classes.btn}
									aria-label="LOG IN"
									type="submit"
								>
									LOGIN
								</Button>
							</form>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
}

LoginPage.propTypes = {
	setToken: PropTypes.func.isRequired
  }

export default LoginPage;
