export const urlLogin = 'https://dev2.expedismart.ch/api-token-auth/';

export const urlTrackingList = 'https://dev2.expedismart.ch/api/shipments/tracking';

export const urlCommentTask = 'https://newadmindb.weboost.dev/basic/web/index.php/comments';

export const urlUsers = 'https://newadmindb.weboost.dev/basic/web/index.php/users';

export const urlStatuslists = 'https://newadmindb.weboost.dev/basic/web/index.php/statuslists';

export const urlStatusshipments = 'https://newadmindb.weboost.dev/basic/web/index.php/statusshipments';

// export const urlUploadPost = 'http://192.168.1.34:3000';
export const urlUploadPost = 'https://newadminfiles.weboost.dev/filesTracking';

export const urlShipmentTaskCommentCount = 'https://newadmindb.weboost.dev/basic/web/index.php/comments/get_shipments_count';

export const urlEnTransit = 'https://dev2.expedismart.ch/api/tracking/?s=';

// https://thingproxy.freeboard.io/fetch/