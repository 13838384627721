import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import SortDate from './SortDate';


const useStyles = makeStyles((theme) => ({
  phoneEmail: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: "1rem",
  },
  center: {
    textAlign: "center",
  },
  textInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "center",
    height: '1.5rem',
    width: "90%",
    borderRadius: "4px",
    border: "1px gray solid",
  },
  addButton: {
      margin: theme.spacing(0),
  },
  search: {
    width: '90%',
  },
  buttonLowerCase: {
    textTransform: "none",
  },
  allLeft: {
    justifyContent: "flex-start",
  },
}));

export default function TrackingHead(props) {
    const classes = useStyles();
    let { searchInput, onInputChange, setSortByDate,
        isClickedFR,
        isClickedEN,
        isClickedDE,
        isClickedExport,
        isClickedImport,
        isClickedDomestic,
        isClickedFedEx,
        isClickedUPS,
        isClickedTNT,
        isClickedAramex,
        isClickedSpring,
        // isClickedNormal,
        // isClickedPremium,
        // isClickedGold,
        setClickedFR,
        setClickedEN,
        setClickedDE,
        setClickedExport,
        setClickedImport,
        setClickedDomestic,
        setClickedFedEx,
        setClickedUPS,
        setClickedTNT,
        setClickedAramex,
        setClickedSpring
        // setClickedNormal,
        // setClickedPremium,
        // setClickedGold,
    } = props;

    return (
    <div>
        <Grid 
            container
            spacing={1}
            direction="row"
            justify="space-around"
            alignItems="flex-start"
        >

            <Grid item xs={2}>
                <form noValidate autoComplete="off" className={classes.center}>
                    <Typography className={classes.phoneEmail} variant="body2" gutterBottom>0848 133 339</Typography>
                    <Link className={classes.phoneEmail} href="mailto:switzerland@fedex.com">switzerland@fedex.com</Link>
                    {isClickedFedEx ? <input className={classes.textInput} type="text" placeholder="Numéros FedEx internat..." /> : undefined}
                </form>
            </Grid>

            <Grid item xs={3}>
                <Grid container spacing={1} className={classes.allLeftb} alignItems="flex-start">
                    <Grid item xs={2} />
                    <Grid item xs={3}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant="outlined"
                            color="default"
                            onClick={() => props.handleAllBtn()}
                        >
                            Tout
                        </Button>
                    </Grid>
                    <Grid item xs={7} />

                    <Grid item xs={2} />
                    <Grid item xs={3}>
                        <Button
                            size="small"
                            variant={isClickedFR ? "contained" : "outlined"}
                            color={isClickedFR ? "secondary" : "default"}
                            onClick={() => setClickedFR(!isClickedFR)}
                        >
                            FR
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            size="small"
                            variant={isClickedEN ? "contained" : "outlined"}
                            color={isClickedEN ? "secondary" : "default"}
                            onClick={() => setClickedEN(!isClickedEN)}
                        >
                            EN
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            size="small"
                            variant={isClickedDE ? "contained" : "outlined"}
                            color={isClickedDE ? "secondary" : "default"}
                            onClick={() => setClickedDE(!isClickedDE)}
                        >
                            DE
                        </Button>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={2} />
                    <Grid item xs={3}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedExport ? "contained" : "outlined"}
                            color={isClickedExport ? "secondary" : "default"}
                            onClick={() => setClickedExport(!isClickedExport)}
                        >
                            Export
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedImport ? "contained" : "outlined"}
                            color={isClickedImport ? "secondary" : "default"}
                            onClick={() => setClickedImport(!isClickedImport)}
                        >
                            Import
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedDomestic ? "contained" : "outlined"}
                            color={isClickedDomestic ? "secondary" : "default"}
                            onClick={() => setClickedDomestic(!isClickedDomestic)}
                        >
                            Domestic
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={5}>
                <Grid 
                    container 
                    spacing={1}
                >
                    <Grid item xs={1} />
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedFedEx ? "contained" : "outlined"}
                            color={isClickedFedEx ? "secondary" : "default"}
                            onClick={() => setClickedFedEx(!isClickedFedEx)}
                        >
                            FedEx
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedUPS ? "contained" : "outlined"}
                            color={isClickedUPS ? "secondary" : "default"}
                            onClick={() => setClickedUPS(!isClickedUPS)}
                        >
                            UPS
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedTNT ? "contained" : "outlined"}
                            color={isClickedTNT ? "secondary" : "default"}
                            onClick={() => setClickedTNT(!isClickedTNT)}
                        >
                            TNT
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedAramex ? "contained" : "outlined"}
                            color={isClickedAramex ? "secondary" : "default"}
                            onClick={() => setClickedAramex(!isClickedAramex)}
                        >
                            Aramex
                        </Button>
                    </Grid>
                        <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant={isClickedSpring ? "contained" : "outlined"}
                            color={isClickedSpring ? "secondary" : "default"}
                            onClick={() => setClickedSpring(!isClickedSpring)}
                        >
                            Spring
                        </Button>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={1} />
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant="contained"
                            disabled
                        >
                            Normal
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant="contained"
                            disabled
                        >
                            Premium
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            className={classes.buttonLowerCase}
                            size="small"
                            variant="contained"
                            disabled
                        >
                            Gold
                        </Button>
                    </Grid>
                    <Grid item xs={5} />

                </Grid>
            </Grid>

            <Grid item xs={2}>
                <Grid 
                    container 
                    spacing={3}
                >
                    <Grid item xs={12}>
                        <form noValidate autoComplete="off">
                            <TextField 
                                size="small" 
                                id="outlined-basic" 
                                label="Rechercher…" 
                                variant="outlined" 
                                className={classes.search}
                                value={searchInput}
                                onChange={e => onInputChange(e.target.value)}
                            />
                        </form>
                    </Grid>
                    <Grid item xs={12}>
                        <SortDate
                            setSortByDate={setSortByDate}
                        />
                    </Grid>
                
                </Grid>
            </Grid>
        </Grid>
    </div>
    );
}
