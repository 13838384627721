import React from 'react';
import clsx from 'clsx';
import { Route, Switch, useHistory } from 'react-router-dom';
import { fade, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, secondaryListItems } from './LeftMenuItems';
import TrackingHeader from '../tracking/TrackingHeader';
import TrackingList from '../tracking/TrackingList';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Shipment from './Shipment';
// import SomeInfoPage from './SomeInfoPage';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import StatusManagement from '../status/StatusManagement';


function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://amanagayev.com/" target="_blank">
        MyCargo Corp.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#122230',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .MuiListItem-button": {
      "& .MuiListItemIcon-root": {
        color: '#fff',
      },
      color: '#fff',
      "&:hover": {
        backgroundColor: '#1f394f',
      },
    },
    "& .MuiListSubheader-root": {
      color: 'white',
    },
    "& .MuiDivider-root": {
      backgroundColor: '#1f394f',
    },
    background: '#122230',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
    "& .MuiListItem-button": {
      "& .MuiListItemIcon-root": {
        color: '#fff',
      },
      color: '#fff',
      "&:hover": {
        backgroundColor: '#1f394f',
      },
    },
    "& .MuiListSubheader-root": {
      color: 'white',
    },
    "& .MuiDivider-root": {
      backgroundColor: '#1f394f',
    },
    background: '#122230',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(4),
  },
  stickyHead: {
    position: 'sticky',
    top: theme.spacing(7),
    zIndex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    background: '#F7F7F7',
  },
  fixedHeight: {
    height: 240,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  textWhite: {
    color: '#fff',
  },
  user: {
    display: 'flex',
  },
  userDown: {
    marginRight: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  grow: {
    flexGrow: 1,
  },
	rootSnackbar: {
		width: '100%',
		'& > * + *': {
		  marginTop: theme.spacing(2),
    },
    // textTransform: "uppercase",
	},
  noneTextTransform: {
    textTransform: "none",
    fontSize: theme.spacing(2),
  },
}));

export default function Frame(props) {
  const classes = useStyles();
  const { stateSnackbar, setSnackbar, currentUser } = props;
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [mySearch, setMySearch] = React.useState();
  const [title, setTitle] = React.useState();
  const [sortByDate, setSortByDate] = React.useState(0);
  
  const [isClickedFR, setClickedFR] = React.useState(true);
  const [isClickedEN, setClickedEN] = React.useState(true);
  const [isClickedDE, setClickedDE] = React.useState(true);
  const [isClickedExport, setClickedExport] = React.useState(true);
  const [isClickedImport, setClickedImport] = React.useState(true);
  const [isClickedDomestic, setClickedDomestic] = React.useState(true);
  const [isClickedFedEx, setClickedFedEx] = React.useState(true);
  const [isClickedUPS, setClickedUPS] = React.useState(true);
  const [isClickedTNT, setClickedTNT] = React.useState(true);
  const [isClickedAramex, setClickedAramex] = React.useState(true);
  const [isClickedSpring, setClickedSpring] = React.useState(true);
  const [isClickedNormal, setClickedNormal] = React.useState(true);
  const [isClickedPremium, setClickedPremium] = React.useState(true);
  const [isClickedGold, setClickedGold] = React.useState(true);

  const anchorRef = React.useRef(null);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen2((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen2(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen2(false);
    }
  }

  const prevOpen = React.useRef(open2);
  React.useEffect(() => {
    if (prevOpen.current === true && open2 === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open2;
  }, [open2]);

  const logOut = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/tracking';
  }

  React.useEffect(() => {
      return history.listen((location) => { 
         setTitle(location.pathname);
         location.pathname === '/tracking' && handleAllBtn();
      }) 
   },[history]);

   function handleAllBtn(){
    return [
      setClickedFR(true),
      setClickedEN(true),
      setClickedDE(true),
      setClickedExport(true),
      setClickedImport(true),
      setClickedDomestic(true),
      setClickedFedEx(true),
      setClickedUPS(true),
      setClickedTNT(true),
      setClickedAramex(true),
      setClickedSpring(true),
      setClickedNormal(true),
      setClickedPremium(true),
      setClickedGold(true)
      ];
   }

   const { openSnackbar, vertical, horizontal } = stateSnackbar;

   const handleCloseSnackbar = () => {
		setSnackbar({ ...stateSnackbar, openSnackbar: false });
	};

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {title === undefined || title === '/' ? 'Home'
            : title === '/tracking' ? 'Tracking'
            : title === '/shipment' ? 'Shipment'
            : title === '/more' ? 'Some Info'
            : title === '/statusManagement' ? 'Status Management'
            : 'MyCargo Corp.'}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Rechercher…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Button
              className={classes.noneTextTransform}
              edge="end"
              ref={anchorRef}
              aria-controls={open2 ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              color="inherit"
            >
              {currentUser !== '' ? currentUser : localStorage.getItem('username')}
            </Button>
            <Popper open={open2} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList autoFocusItem={open2} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={logOut}>Log Out</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon className={classes.textWhite} />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
        <List>{secondaryListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
            <Switch>
              <Route path="/tracking">
                <Grid container spacing={1}>
                  <Grid item xs={12} md={12} lg={12} className={classes.stickyHead}>
                    <Paper className={classes.paper}>
                      <TrackingHeader
                        searchInput={mySearch}
                        onInputChange={e => setMySearch(e)}
                        setSortByDate={setSortByDate}
                        isClickedFR={isClickedFR}
                        isClickedEN={isClickedEN}
                        isClickedDE={isClickedDE}
                        isClickedExport={isClickedExport}
                        isClickedImport={isClickedImport}
                        isClickedDomestic={isClickedDomestic}
                        isClickedFedEx={isClickedFedEx}
                        isClickedUPS={isClickedUPS}
                        isClickedTNT={isClickedTNT}
                        isClickedAramex={isClickedAramex}
                        isClickedSpring={isClickedSpring}
                        isClickedNormal={isClickedNormal}
                        isClickedPremium={isClickedPremium}
                        isClickedGold={isClickedGold}
                        setClickedFR={setClickedFR}
                        setClickedEN={setClickedEN}
                        setClickedDE={setClickedDE}
                        setClickedExport={setClickedExport}
                        setClickedImport={setClickedImport}
                        setClickedDomestic={setClickedDomestic}
                        setClickedFedEx={setClickedFedEx}
                        setClickedUPS={setClickedUPS}
                        setClickedTNT={setClickedTNT}
                        setClickedAramex={setClickedAramex}
                        setClickedSpring={setClickedSpring}
                        setClickedNormal={setClickedNormal}
                        setClickedPremium={setClickedPremium}
                        setClickedGold={setClickedGold}
                        handleAllBtn={handleAllBtn}
                      />
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TrackingList
                      input={mySearch}
                      sortByDate={sortByDate}
                      isClickedFR={isClickedFR}
                      isClickedEN={isClickedEN}
                      isClickedDE={isClickedDE}
                      isClickedExport={isClickedExport}
                      isClickedImport={isClickedImport}
                      isClickedDomestic={isClickedDomestic}
                      isClickedFedEx={isClickedFedEx}
                      isClickedUPS={isClickedUPS}
                      isClickedTNT={isClickedTNT}
                      isClickedAramex={isClickedAramex}
                      isClickedSpring={isClickedSpring}
                      isClickedNormal={isClickedNormal}
                      isClickedPremium={isClickedPremium}
                      isClickedGold={isClickedGold}
                    />
                  </Grid>
                </Grid>
              </Route>
              <Route path="/shipment">
                <Shipment />
              </Route>
              {/* <Route path="/more">
                <SomeInfoPage />
              </Route> */}
              <Route path="/statusManagement">
                <StatusManagement />
              </Route>
            </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
        <div className={classes.rootSnackbar}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            key={vertical + horizontal}
          >
            <Alert onClose={handleCloseSnackbar} severity="success">
              Welcome {currentUser}!
            </Alert>
          </Snackbar>
        </div>
      </main>
    </div>
  );
}
