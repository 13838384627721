import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';
import SortIcon from '@material-ui/icons/Sort';
import SvgIcon from '@material-ui/core/SvgIcon';


const useStyles = makeStyles((theme) => ({
  sortBtn: {
    width: '90%',
    textTransform: "none",
  },
  sortIcon: {
    paddingRight: theme.spacing(1),
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
    //   backgroundColor: theme.palette.action.disabledBackground,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: '#000', // color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function DlvDescIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M18 7H15L19 3L23 7H20V21H18V7M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
      </SvgIcon>
    );
}

function DlvAscIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M20 17H23L19 21L15 17H18V3H20V17M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M10.19 14.53L7 12.69V9H8.5V11.82L10.94 13.23L10.19 14.53Z" />
      </SvgIcon>
    );
}

function PickDescIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M18 7H15L19 3L23 7H20V21H18V7M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M8 7.15C10.67 7.15 12.85 9.32 12.85 12C12.85 14.68 10.68 16.85 8 16.85C5.32 16.85 3.15 14.68 3.15 12C3.15 9.32 5.32 7.15 8 7.15M7 9V12.69L10.19 14.53L10.94 13.23L8.5 11.82V9" />
      </SvgIcon>
    );
}

function PickAscIcon(props) {
    return (
      <SvgIcon {...props}>
        <path fill="currentColor" d="M20 17H23L19 21L15 17H18V3H20V17M8 5C4.14 5 1 8.13 1 12C1 15.87 4.13 19 8 19C11.86 19 15 15.87 15 12C15 8.13 11.87 5 8 5M8 7.15C10.67 7.15 12.85 9.32 12.85 12C12.85 14.68 10.68 16.85 8 16.85C5.32 16.85 3.15 14.68 3.15 12C3.15 9.32 5.32 7.15 8 7.15M7 9V12.69L10.19 14.53L10.94 13.23L8.5 11.82V9" />
      </SvgIcon>
    );
}

export default function SortDate(props) {
  const { setSortByDate } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [titleName, setTitleName] = React.useState('Sorted by: Default');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={classes.sortBtn}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="default"
        onClick={handleClick}
      >
        <SortIcon className={classes.sortIcon} fontSize="default" />{titleName}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Sorted by: Default');
                setSortByDate(0);
            }}
            selected={false}
        >
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Default" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Delivery date Desc');
                setSortByDate(1);
            }}
            selected={false}
        >
          <ListItemIcon>
            <DlvAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delivery date Desc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Delivery date Asc');
                setSortByDate(2);
            }}
            selected={false}
        >
          <ListItemIcon>
            <DlvDescIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delivery date Asc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Pickup date Desc');
                setSortByDate(3);
            }}
            selected={false}
        >
          <ListItemIcon>
            <PickAscIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Pickup date Desc" />
        </StyledMenuItem>
        <StyledMenuItem
            onClick={() => {
                handleClose();
                setTitleName('Pickup date Asc');
                setSortByDate(4);
            }}
            selected={false}
        >
          <ListItemIcon>
            <PickDescIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Pickup date Asc" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
