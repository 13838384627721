import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { urlUsers } from '../common/Constants';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formControl: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(2),
        width: "95%",
    },
    toWho: {
        width: "80%",
    },
    dateTimeContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    dateTime: {
        width: 200,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//   {
//     "id": 1,
//     "username": "iKompar"
//   },
//   {
//     "id": 2,
//     "username": "Robert"
//   },
//   {
//     "id": 3,
//     "username": "Hans"
//   }
// ];

// const toIsoStringCurrentTimeZone = function() {
//     const pad = function(num) {
//             const norm = Math.floor(Math.abs(num));
//             return (norm < 10 ? '0' : '') + norm;
//         };
//     return this.getFullYear() +
//         '-' + pad(this.getMonth() + 1) +
//         '-' + pad(this.getDate()) +
//         'T' + pad(this.getHours()) +
//         ':' + pad(this.getMinutes()) +
//         ':' + pad(this.getSeconds());
// };

export default function TaskForm(props) {
    const [task, setTask] = useState('');
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [personName, setPersonName] = useState([]);
    const [users, setUsers] = useState([]);
    const [dueTo, setDueTo] = useState(new Date());

    useEffect(() => {
        fetch(urlUsers)
          .then(response => response.json())
          .then(json => setUsers(json))
          .catch(error => console.error(error))
      }, []);

    function handleChange(e) {
        setTask(e.target.value);
    }

    const handleChange2 = (event) => {
        setPersonName(event.target.value);
    };
    
    function handleChange3(e) {
        setDueTo(e.target.value);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };
    
      const handleClose = () => {
        setOpen(false);
    };

    return(
    <div>
        <Tooltip title="Ajouter une tâche" onClick={handleClickOpen}>
            <IconButton aria-label="add">
                <AddCircleOutlineRoundedIcon />
            </IconButton>
        </Tooltip>
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
            aria-labelledby="form-dialog-title"
            className={classes.root}
        >
            <Grid
                container
                direction="row"
                justify="space-around"
                alignItems="flex-start"
                className={classes.formControl}
            >
                <Grid item xs={6}>
                    <FormControl className={classes.toWho}>
                        <InputLabel id="task-mutiple-checkbox-label" className={classes.dropDown}>Tâche assignée à:</InputLabel>
                        <Select
                            labelId="users-mutiple-checkbox-label"
                            id="users-mutiple-checkbox"
                            multiple
                            value={personName}
                            onChange={handleChange2}
                            input={<Input />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={MenuProps}
                        >
                            {users.map((item) => (
                                <MenuItem key={item.username} value={item.username}>
                                    <Checkbox checked={personName.indexOf(item.username) > -1} />
                                    <ListItemText primary={item.username} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={6} /> */}
                <Grid item xs={6}>
                    <form className={classes.dateTimeContainer} noValidate>
                        <TextField
                            id="datetime-task"
                            label="Due to:"
                            type="datetime-local"
                            // defaultValue={(new Date().toISOString()).slice(0, 16)}
                            className={classes.dateTime}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            // value={dueTo}
                            // defaultValue={dueTo}
                            defaultValue={(new Date(dueTo).getFullYear()).toString()+'-'+(new Date(dueTo).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo).getDate()).toString().padStart(2, '0')+'T'+(new Date(dueTo).getHours()).toString().padStart(2, '0')+':'+(new Date(dueTo).getMinutes()).toString().padStart(2, '0')}
                            onChange={handleChange3}
                        />
                    </form>
                </Grid>
            </Grid>
            <DialogTitle>
                Tâche à faire :
            </DialogTitle>
            {/* {console.log(dueTo)} */}
            {/* {console.log((new Date(dueTo).getFullYear()).toString()+'-'+(new Date(dueTo).getMonth()+1).toString().padStart(2, '0')+'-'+(new Date(dueTo).getDate()).toString().padStart(2, '0')+'T'+(new Date(dueTo).getHours()).toString().padStart(2, '0')+':'+(new Date(dueTo).getMinutes()).toString().padStart(2, '0'))} */}
            <DialogContent>
                <form id="formTask" onSubmit={(e) => {
                    e.preventDefault();
                    task.trim() !== '' ? props.addTask(task, personName.toString(), dueTo) : handleClose();
                    setTask('');
                }}>
                    <TextField
                        className={classes.textField}
                        autoFocus
                        margin="dense"
                        id="new-task-input"
                        type="text"
                        multiline
                        rows={14}
                        fullWidth
                        value={task}
                        onChange={handleChange}
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    type="submit"
                    form="formTask" 
                    onClick={handleClose}
                >
                    Enregistrer
                </Button>
            </DialogActions>
      </Dialog>
    </div>
    );
}


// {
//     for(let i=0; i<selected.length; i++){
//         for(let j=0; j<users.length; j++){
//             if(selected[i]===users[j].id){
//                 console.log(selected[i], users[j].username);
//             }
//         }
//     }
//     selected.join(', ');
// }

// {props.users.map(item => {
//     if(item.id in selected){
//         return selected[selected.indexOf(item.id)] = item.username;
//     }
//     return null;
// })
// selected.join(', ');
// }

// selected.join(', ')