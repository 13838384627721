import React, { useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    form: {
        '& > *': {
          margin: theme.spacing(1),
          width: '75ch',
        },
    },
}));

function StatusForm(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleChange(e) {
        setNewStatus(e.target.value);
    }

    return (
        <div>
          <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            Add New Status
          </Button>
          <Dialog
            fullScreen={fullScreen}
            // fullWidth
            // maxWidth="sm"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">{"New status:"}</DialogTitle>
            <DialogContent>
                <form
                    id="formStatus"
                    className={classes.form}
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => {
                        e.preventDefault();
                        newStatus.trim() !== '' ? props.addStatus(newStatus) : handleClose();
                        setNewStatus('');
                    }}
                >
                    <TextField
                        id="outlined-basic"
                        autoFocus
                        label="Name"
                        variant="outlined"
                        type="text"
                        value={newStatus}
                        onChange={handleChange}
                    />
                </form>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                type="submit"
                form="formStatus"
                onClick={(handleClose)}
            >
                Add
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
}

export default StatusForm;