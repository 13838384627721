import React, { useState, useEffect } from 'react';
import { urlStatuslists, urlUsers } from '../common/Constants';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import StatusForm from './StatusForm';
import StatusEditingForm from './StatusEditingForm';
import DeleteIcon from '@material-ui/icons/Delete';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
    },
    tableRow: {
        '& .MuiTableCell-head': {
            fontWeight: 700,
        },
    },
    form: {
        '& > *': {
          margin: theme.spacing(1),
          width: '75ch',
        },
    },
    stickyHead: {
        position: 'sticky',
        top: theme.spacing(8),
        zIndex: 1,
        background: '#fafafa',
        padding: theme.spacing(1),
    },
}));

function StatusListRow(prop) {
    const { status,
        deleteStatus,
        setRefresh
    } = prop;
    const [userCreated,setUserCreated] = useState({});
    const [userUpdated,setUserUpdated] = useState({});

    useEffect(() => {
        fetch(`${urlUsers}/${status.created_by}`)
            .then(res => res.json())
            .then(json => setUserCreated(json))
            .catch(err => console.error(err))
    }, [status.created_by]);
    
    useEffect(() => {
        fetch(`${urlUsers}/${status.updated_by}`)
            .then(res => res.json())
            .then(json => setUserUpdated(json))
            .catch(err => console.error(err))
    }, [status.updated_by]);

    function editStatus(name) {
        fetch(`${urlStatuslists}/${status.id}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: name, updated_by: 1})
        })
            .then(res => setRefresh(oldKey => oldKey +1))
            .catch(err => console.error(err))
    }
    const deleteBtn = (<Tooltip title="Delete" onClick={() => deleteStatus(status.id)}>
        <IconButton aria-label="delete">
            <DeleteIcon />
        </IconButton>
    </Tooltip>);

    return(<React.Fragment>
        <TableRow>
            <TableCell>{status.name}</TableCell>
            <TableCell>{userCreated.username}</TableCell>
            <TableCell>{(new Date(status.created_at*1000).getFullYear()).toString() + '-' + (new Date(status.created_at*1000).getMonth()+1).toString().padStart(2, '0') + '-' + (new Date(status.created_at*1000).getDate()).toString().padStart(2, '0') + ' ' + (new Date(status.created_at*1000).getHours()).toString().padStart(2, '0') + ':' + (new Date(status.created_at*1000).getMinutes()).toString().padStart(2, '0')}</TableCell>
            <TableCell />
            <TableCell>{status.updated_at === status.created_at ? '' : userUpdated.username}</TableCell>
            <TableCell>{status.updated_at === status.created_at ? '' : ((new Date(status.updated_at*1000).getFullYear()).toString() + '-' + (new Date(status.updated_at*1000).getMonth()+1).toString().padStart(2, '0') + '-' + (new Date(status.updated_at*1000).getDate()).toString().padStart(2, '0') + ' ' + (new Date(status.updated_at*1000).getHours()).toString().padStart(2, '0') + ':' + (new Date(status.updated_at*1000).getMinutes()).toString().padStart(2, '0'))}</TableCell>
            <TableCell>
                <Grid container>
                    <Grid item xs={6}>
                        <StatusEditingForm
                            statusName={status.name}
                            editStatus={editStatus}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {status.id !== 1 ? deleteBtn : null}
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    </React.Fragment>);
}

function StatusManagement() {
    const classes = useStyles();
    const [statusList,setStatusList] = useState([]);
    const [refresh,setRefresh] = useState(0);

    useEffect(() => {
        fetch(urlStatuslists)
            .then(res => res.json())
            .then(json => setStatusList(json))
            .catch(err => console.error(err))
    }, [refresh]);

    function addStatus(name) {
        fetch(urlStatuslists, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: name, created_by: 1})
        })
            .then(res => setRefresh(oldKey => oldKey + 1))
            .catch(err => console.error(err))
    }

    function deleteStatus(id) {
        fetch(`${urlStatuslists}/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then(res => setRefresh(oldKey => oldKey + 1))
            .catch(err => console.error(err))
    }
    
    return(
        <div>
            {/* <div className={classes.stickyHead}>
                <StatusForm addStatus={addStatus} />
            </div> */}
            <div className={classes.stickyHead}>
                <TableContainer>
                    <Table>
                        <colgroup>
                            <col span="1" style={{width: "20%"}} />
                            <col span="1" style={{width: "7%"}} />
                            <col span="1" style={{width: "7%"}} />
                            <col span="1" style={{width: "7%"}} />
                            <col span="1" style={{width: "7%"}} />
                            <col span="1" style={{width: "7%"}} />
                            <col span="1" style={{width: "5%"}} />
                        </colgroup>
                        <TableHead className={classes.stickyHead}>
                            <TableRow colSpan={7}>
                                <TableCell><StatusForm addStatus={addStatus} /></TableCell>
                            </TableRow>
                            <TableRow className={classes.tableRow}>
                                <TableCell>Name</TableCell>
                                <TableCell>Created by</TableCell>
                                <TableCell>Created at</TableCell>
                                <TableCell />
                                <TableCell>Updated by</TableCell>
                                <TableCell>Updated at</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                    </Table>
                </TableContainer>
            </div>
            <TableContainer className={classes.root}>
                <Table>
                    <colgroup>
                        <col span="1" style={{width: "20%"}} />
                        <col span="1" style={{width: "7%"}} />
                        <col span="1" style={{width: "7%"}} />
                        <col span="1" style={{width: "7%"}} />
                        <col span="1" style={{width: "7%"}} />
                        <col span="1" style={{width: "7%"}} />
                        <col span="1" style={{width: "5%"}} />
                    </colgroup>
                    {/* <TableHead className={classes.stickyHead}>
                        <TableRow colSpan={7}>
                            <TableCell><StatusForm addStatus={addStatus} /></TableCell>
                        </TableRow>
                        <TableRow className={classes.tableRow}>
                            <TableCell>Name</TableCell>
                            <TableCell>Created by</TableCell>
                            <TableCell>Creaated at</TableCell>
                            <TableCell />
                            <TableCell>Updated by</TableCell>
                            <TableCell>Updated at</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead> */}
                    <TableBody>
                        {statusList.map(status => {
                            return <StatusListRow
                                key={status.id}
                                status={status}
                                deleteStatus={deleteStatus}
                                setRefresh={setRefresh}
                            />;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default StatusManagement;
