import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Get } from 'react-axios';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import OuvrirPopUp from './OuvrirPopUp';
import TrackingPopUpDelete from '../tracking/TrackingPopUpDelete';
import useToken from '../login/useToken';
import TablePagination from '@material-ui/core/TablePagination';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import MuiTableCell from "@material-ui/core/TableCell";
import SvgIcon from '@material-ui/core/SvgIcon';
import { urlTrackingList, urlStatuslists, urlStatusshipments, urlShipmentTaskCommentCount, urlEnTransit } from '../common/Constants';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
// import { CountCommentsTasksContext } from '../common/tracking-context';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FlightLandIcon from '@material-ui/icons/FlightLand';


const useStyles = makeStyles((theme) => ({
  standardTextAlignCenter: {
    textAlign: 'center',
  },
  shippingInformation: {
    textAlign: 'center',
    fontSize: ".8rem",
  },
  senderReceiver: {
    textAlign: 'left',
    fontSize: ".8rem",
    width: '5ch',
  },
  table: {
    backgroundColor: "#F7F7F7",
  },
  tableHeadCell: {
      textAlign: 'center',
  },
  arrowDown: {
    textAlign: 'center',
    padding: 0,
  },
  arrowDropIcon: {
      color: "secondary",
      width: "4ch",
      fontSize: "2rem",
  },
  status: {
    textAlign: 'center',
    minWidth: '90%',
  },
  statusRed: {
    textAlign: 'center',
    minWidth: '90%',
    // color: 'red',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '& .MuiSelect-iconOutlined': {
        color: 'red',
      }
    },
  },
  statutLivraison: {
      backgroundColor: "#43a047",
      padding: "0.5rem",
      color: "#FFF",
      marginBottom: 0,
      letterSpacing: theme.spacing(0.1),
    },
    statutAujourdhui: {
      backgroundColor: "#2979ff",
      padding: "0.5rem",
      color: "#FFF",
      marginBottom: 0,
      letterSpacing: theme.spacing(0.1),
    },
    statutRetard: {
      backgroundColor: "#B71C1C",
      padding: "0.5rem",
      color: "#FFF",
      marginBottom: 0,
      letterSpacing: theme.spacing(0.1),
    },
    noDeliveryDate: {
      // backgroundColor: "#B71C1C",
      padding: "0.5rem",
      // color: "#FFF",
      marginBottom: 0,
      letterSpacing: theme.spacing(0.1),
    },
    statutExplanation: {
      backgroundColor: "#ff5454",
      padding: "0.5rem",
      fontSize: "1rem",
      marginTop: 0,
    },
    motif:{
      fontSize: ".9rem",
      color: "grey",
      fontWeight: "700",
      padding: theme.spacing(1.35),
    },
    motifRed:{
      fontSize: ".9rem",
      color: "red",
      fontWeight: "700",
      padding: theme.spacing(1.35),
    },
    commentaries: {
      fontSize: ".8rem",
      padding: theme.spacing(1.4),
    },
    taches: {
      backgroundColor: "#ff8f8f",
      padding: "0.65rem",
      fontSize: ".8rem",
    },
    buttonLowerCase: {
      textTransform: "none",
    },
    deleteB: {
      display: "block",
      "&:hover": {
        display: "none",
      },
    },
    moreInfo: {
      background: 'white',
    },
    root: {
      '&.MuiPaper-root': {
        backgroundColor: 'inherit',
      },
    },
    packageIcon: {
      textAlign: 'left',
    },
    flipHorizontal: {
      transform: 'scaleX(-1)',
    },
    deleteIcon: {
      textAlign: 'right',
    },
    paddingLeftZero: {
      paddingLeft: 0,
    },
    rowWithInformations: {
      paddingBottom: 0,
      marginBottom: 0,
      // background: 'blue',
    },
    rowWithInformationsClicked: {
      paddingBottom: 0,
      marginBottom: 0,
      background: 'blue',
    },
    shippingIconRed: {
      color: 'red',
    },
    enTransitHead: {
      color: 'gray',
    },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const TableCellS = withStyles({
  root: {
    borderBottom: "none",
    paddingBottom: 0,
  }
})(MuiTableCell);
    
function PackageIcon(props) {
  return (
    <SvgIcon {...props}>
      <path fill="currentColor" d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L10.11,5.22L16,8.61L17.96,7.5L12,4.15M6.04,7.5L12,10.85L13.96,9.75L8.08,6.35L6.04,7.5M5,15.91L11,19.29V12.58L5,9.21V15.91M19,15.91V9.21L13,12.58V19.29L19,15.91Z" />
    </SvgIcon>
  );
}

function DateDifferences(props) {
  const { arg } = props;
  const classes = useStyles();
  const test = (new Date('2021-01-13')).getTime();
  // let dateNow = Date.now(); //  1611067478014
  const dateDelivery = (new Date(arg)).getTime();
  const diffTime = Math.abs(test - dateDelivery); //milliseconds
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  if(test < dateDelivery){
    return <p className={classes.statutLivraison}><b>Livraison<br />J+{diffDays}</b></p>;
  } else if (test === dateDelivery) {
    return <p className={classes.statutAujourdhui}><b>Aujourd’hui</b></p>;
  } else if (test > dateDelivery){
    return <p className={classes.statutRetard}><b>Retard<br />J-{diffDays}</b></p>;
  } else if (isNaN(dateDelivery)) {
    return <p className={classes.noDeliveryDate}><b>No Deliveri Date</b></p>;
  } else {
    return <p className={classes.noDeliveryDate}><b>Unknown</b></p>;
  }
}

function Row(props) {
  const { item } = props;
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [statusValue, setStatusValue] = useState(1);
  // const [rowId, setRowId] = React.useState(0);
  const [statusList, setStatusList] = useState([]);
  const [refreshKey,setRefreshKey] = useState(0);
  const [ifExist,setIfExist] = useState(-1);
  const [isClickedRow,setIsClickedRow] = useState(false);
  const [carrierProblem,setCarrierProblem] = useState(false);
  const [ifInsurance,setIfInsurance] = useState(false);
  const [enTransit,setEnTransit] = useState([]);

  useEffect(() => {
    fetch(urlStatuslists)
        .then(response => response.json())
        .then(json => setStatusList(json))
        .catch(error => console.error(error))
  }, [refreshKey]);
  
  useEffect(() => {
    fetch(urlStatusshipments)
        .then(response => response.json())
        .then(json => json.map(status => {
          if(status.shipment_id === item.id){
            return [setStatusValue(status.status_id), setIfExist(status.id)];
          }
          return null;
        }))
        .catch(error => console.error(error))
  }, [item, refreshKey]);

  useEffect(() => {
    fetch(`${urlEnTransit}${item.id}`, {
        method: 'GET',
        headers: {
          'authorization': `JWT ${token}`,
        }
      })
      .then(response => response.json())
      .then(json => {
        if(json.length >= 1){
          return setEnTransit(json);
        }
        return null;
      })
      .catch(error => console.error(error))
  }, [item.id]);

  // , {
  //   method: 'GET',
  //   headers: {
  //     'authorization': `JWT ${token}`,
  //   }
  // }

  const username = localStorage.getItem('username');
  const currentUser = username === 'ikompar' ? 1 : 0;

  function postStatus(shipmentId, statusId) {
    fetch(urlStatusshipments, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({shipment_id: shipmentId, status_id: statusId, created_by: currentUser})
    })
    .then(response => {setRefreshKey(oldKey => oldKey + 1);})
    .catch(error => console.error(error))
  };
  
  function editStatus(shipmentId, statusId) {
    fetch(`${urlStatusshipments}/${ifExist}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({shipment_id: shipmentId, status_id: statusId, updated_by: currentUser})
    })
    .then(response => {setRefreshKey(oldKey => oldKey + 1);})
    .catch(error => console.error(error))
  };

  // const handleChange2 = (event) => {
  //   setStatusValue(event.target.value);
  // };

  //  className={rowId !== item.id ? classes.rowWithInformations : classes.rowWithInformationsClicked}
 
  // onClick={setIsClickedRow(true)}  selected={true}

  // console.log(statusValue);

  const carrierProblemIcon = (<Grid item xs={6}>
    <Tooltip
      title="Carrier Problem"
      className={classes.shippingIconRed}
      placement="left-end"
    >
      <IconButton aria-label="carrierProblem">
        <LocalShippingIcon fontSize='large' />
      </IconButton>
    </Tooltip>
  </Grid>);
  
  const insuranceIcon = (<Grid item xs={6}>
    <Tooltip
      title="Axa Insurance"
      // className={classes.shippingIconRed}
      placement="top-start"
    >
      <IconButton aria-label="insurance">
        <LocalHospitalIcon fontSize='large' />
      </IconButton>
    </Tooltip>
  </Grid>);

  console.log(enTransit);

  return (
    <React.Fragment>
      <TableRow selected={isClickedRow}>
        <TableCellS className={classes.shippingInformation} component="th" scope="row">
          <span>Livraison prévue:</span><br />
          <span>{item.delivery_date}</span><br /><br />
          {/* <span>ID:</span><br />
          <span>{item.id}</span><br /><br /> */}
          <span>N° suivi:</span><br />
          <span>{item.tracking_numbers.replace(/<br\/>/g, "\n")}</span><br /><br />
          <span>N° enlèvement:</span><br />
          <span>{item.pickup_request.confirmation_number}</span>
        </TableCellS>
        <TableCellS className={classes.senderReceiver}>
          <span><b>{item.pickup_contact}</b></span><br />
          <span>{item.pickup_address}</span><br />
          <span>{item.pickup_phone}</span><br />
          <a href="mailto:">{item.user}</a>
        </TableCellS>
        <TableCellS className={classes.senderReceiver}>
          <span><b>{item.delivery_contact}</b></span><br />
          <span>{item.delivery_address}</span><br />
          <span>{item.delivery_phone}</span><br />
          <a href="mailto:">{item.user}</a>
        </TableCellS>
        <TableCellS className={classes.standardTextAlignCenter}>
        <div className="flex flex-shrink text-center justify-between sm:w-88 grid grid-cols-1 gap-4">
						<span><DateDifferences arg={item.delivery_date} /></span>
					</div>
        </TableCellS>
        <TableCellS className={classes.standardTextAlignCenter}>
            <TextField
              className={statusValue === 1 ? classes.status : classes.statusRed}
              select
              value={statusValue}
              onChange={ifExist === -1 ? (e) => postStatus(item.id, e.target.value) : (e) => editStatus(item.id, e.target.value)}
              SelectProps={{
                native: true,
              }}
              InputProps={{
                classes: {
                  input: statusValue === 1 ? classes.motif : classes.motifRed,
                },
              }}
              variant="outlined"
            >
              {statusList.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </TextField>
        </TableCellS>

        {/* <TableCellS className={classes.standardTextAlignCenter}>
          <form noValidate autoComplete="off">
              <TextField
                placeholder="Le client attends une réponse…"
                variant="outlined"
                size="small"
                InputProps={{
                  classes: {
                    input: classes.commentaries,
                  },
                }}
              />
          </form>
        </TableCellS> */}

        <TableCellS
          className={classes.standardTextAlignCenter}
          // onClick={() => setIsClickedRow(false)}
        >
          <OuvrirPopUp
            trackingId={item.id}
            trackingNumber={item.tracking_numbers}
            pickupContact={item.pickup_contact}
            deliveriContact={item.delivery_contact}
            pickupAddress={item.pickup_address}
            deliveriAddress={item.delivery_address}
            pickupPhone={item.pickup_phone}
            deliveriPhone={item.delivery_phone}
            pickupEmail={item.user}
            deliveriEmail={item.user}
            // onClick={() => setRowId(item.id)}
          />
        </TableCellS>

        {/* <TableCellS className={classes.standardTextAlignCenter}>
          <p className={classes.taches}>1 en cours</p>
        </TableCellS> */}

        <TableCellS>
          <Grid container>
            <Grid item xs={6} className={classes.packageIcon}>
              <Tooltip
                className={classes.paddingLeftZero}
                title={<div>
                  {item.package_group.goods.map(i => {
                    return <div>- {i.quantity} {i.description}<br /></div>
                  })}
                  <br />Total CHF: {item.package_group.customs_total_value} -
                  contenu</div>}
                placement="left-end"
                >
                <IconButton>
                  <Typography >x{item.package_count}</Typography >
                  <PackageIcon className={classes.flipHorizontal} fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>
            {ifInsurance ? insuranceIcon : <Grid item xs={6} />}
            {carrierProblem ? carrierProblemIcon : <Grid item xs={6} />}
            <Grid item xs={6} className={classes.deleteIcon}>
              <Tooltip title="Delete" className={classes.delete}>
                <IconButton aria-label="delete">
                  <TrackingPopUpDelete />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </TableCellS>
      </TableRow>
      <TableRow selected={isClickedRow} className={classes.rowWithInformations}>
        <TableCell colSpan={9} className={classes.arrowDown}>
          <IconButton 
            title="En transit"
            aria-label="expand row"
            onClick={() => setOpen(!open)}>
            {open ? <ExpandLessIcon color="inherit" className={classes.arrowDropIcon} /> : <ExpandMoreIcon color="inherit" className={classes.arrowDropIcon} />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow className={classes.moreInfo}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                En transit
              </Typography>
              <Table size="small" aria-label="purchases">
                <colgroup>
                  <col span="1" style={{width: "65%"}} />
                  <col span="1" style={{width: "35%"}} />
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.enTransitHead}><h3>Event</h3></TableCell>
                    <TableCell className={classes.enTransitHead}><h3>Location</h3></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enTransit.map(transit => {
                    return (<React.Fragment>
                      <TableRow><TableCell colSpan={2}><h2>{transit.date.slice(0, 10)}</h2></TableCell></TableRow>
                      {transit.events.map(eventsDetails => {
                        return (<TableRow>
                          <TableCell>{eventsDetails.last_modified.slice(11, 16)}<br />{eventsDetails.event_desc}</TableCell><TableCell>{eventsDetails.area_desc}</TableCell>
                        </TableRow>);
                      })}
                    </React.Fragment>);
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function TablePaginationActions(props) {
  const classes = useStyles2();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const wordKeyToSearch = ['tracking_numbers', 'delivery_date', 'pickup_contact', 'pickup_address', 'pickup_phone', 'user', 'delivery_contact', 'delivery_address', 'delivery_phone'];

const wordKeyToSearchPickupRequest = ['confirmation_number'];

function customSearch(wordtosearch) {
  let theWord = '';

  for (const [key, value] of Object.entries(wordtosearch)) {
    if(wordKeyToSearch.indexOf(key) !== -1){
      theWord += value + ' ';
    }
  }
  
  for (const [key, value] of Object.entries(wordtosearch.pickup_request)) {
    if(wordKeyToSearchPickupRequest.indexOf(key) !== -1){
      theWord += value + ' ';
    }
  }

  return theWord;
}

// Sorting by date: default, pickup_date, delivery_date
const SORT_BY_DATE = {
  0: () => true,
  1: (a, b) => (a.delivery_date < b.delivery_date) ? 1 : -1,
  2: (a, b) => (a.delivery_date > b.delivery_date) ? 1 : -1,
  3: (a, b) => (a.pickup_date < b.pickup_date) ? 1 : -1,
  4: (a, b) => (a.pickup_date > b.pickup_date) ? 1 : -1
}
 
export default function TrackingList(props) {
  const classes = useStyles();
  const { token } = useToken();
  const { input, sortByDate,
    isClickedFR,
    isClickedEN,
    isClickedDE,
    isClickedExport,
    isClickedImport,
    isClickedDomestic,
    isClickedFedEx,
    isClickedUPS,
    isClickedTNT,
    isClickedAramex,
    isClickedSpring,
    isClickedNormal,
    isClickedPremium,
    isClickedGold
  } = props;

  let [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [countItems, setCountItems] = React.useState(0);
  // const [countCommentsTasks,setCountCommentsTasks] = useState([]);
  
  // useEffect(() => {
  //   fetch(urlShipmentTaskCommentCount)
  //     .then(res => res.json())
  //     .then(json => setCountCommentsTasks(json))
  //     .catch(err => console.error(err))
  // }, []);

  // const myTest = [{first: {first: '1st'}}];

  const authAxios = axios.create({
    baseURL: {urlTrackingList},
    timeout: 15000,
    headers: {
      'authorization': `JWT ${token}`,
    }
  });

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, countItems - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const FILTER_MAP = {
  //   FilterButtons: item => false
  //   || (isClickedFR && item.language === 'FR')
  //   || (isClickedEN && item.language === 'EN')
  //   || (isClickedDE && item.language === 'DE')
  //   || (isClickedExport && item.pickup_address.trim().slice(-2) === 'CH' & item.delivery_address.trim().slice(-2) !== 'CH')
  //   || (isClickedImport && item.pickup_address.trim().slice(-2) !== 'CH' & item.delivery_address.trim().slice(-2) === 'CH')
  //   || (isClickedDomestic && item.pickup_address.trim().slice(-2) === 'CH' & item.delivery_address.trim().slice(-2) === 'CH')
  //   || (isClickedFedEx && item.quote_transporter === 'FedEx')
  //   || (isClickedUPS && item.quote_transporter === 'UPS')
  //   || (isClickedTNT && item.quote_transporter === 'TNT')
  //   || (isClickedAramex && item.quote_transporter === 'ARAMEX')
  //   || (isClickedSpring && item.quote_transporter === 'SPRINGGPA')
  //   || (isClickedNormal && item.customer === 'Normal')
  //   || (isClickedPremium && item.customer === 'Premium')
  //   || (isClickedGold && item.customer === 'Gold')
  // };

  const FILTER_MAP = {
    FilterButtons: item => false
    || (isClickedFedEx && item.quote_transporter === 'FedEx')
    || (isClickedUPS && item.quote_transporter === 'UPS')
    || (isClickedTNT && item.quote_transporter === 'TNT')
    || (isClickedAramex && item.quote_transporter === 'ARAMEX')
    || (isClickedSpring && item.quote_transporter === 'SPRINGGPA'),
    Languages: item => false
    || (isClickedFR && item.language === 'FR')
    || (isClickedEN && item.language === 'EN')
    || (isClickedDE && item.language === 'DE'),
    Type: item => false
    || (isClickedExport && item.pickup_address.trim().slice(-2) === 'CH' & item.delivery_address.trim().slice(-2) !== 'CH')
    || (isClickedImport && item.pickup_address.trim().slice(-2) !== 'CH' & item.delivery_address.trim().slice(-2) === 'CH')
    || (isClickedDomestic && item.pickup_address.trim().slice(-2) === 'CH' & item.delivery_address.trim().slice(-2) === 'CH'),
    Customer: item => false
    || (isClickedNormal && item.customer === 'Normal')
    || (isClickedPremium && item.customer === 'Premium')
    || (isClickedGold && item.customer === 'Gold')
  };

  return (
    <div>
    <TableContainer className={classes.root} component={Paper}>
      <Table className={classes.table} aria-label="collapsible table">
        <colgroup>
          <col span="1" style={{width: "13%"}} />
          <col span="1" style={{width: "17%"}} />
          <col span="1" style={{width: "17%"}} />
          <col span="1" style={{width: "10%"}} />
          <col span="1" style={{width: "14%"}} />
          {/* <col span="1" style={{width: "15%"}} /> */}
          {/* <col span="1" style={{width: "8%"}} /> */}
          <col span="1" style={{width: "19%"}} />
          <col span="1" style={{width: "10%"}} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>Information envoi</TableCell>
            <TableCell className={classes.tableHeadCell}>Expéditeur</TableCell>
            <TableCell className={classes.tableHeadCell}>Destinataire</TableCell>
            <TableCell className={classes.tableHeadCell}>Jours</TableCell>
            <TableCell className={classes.tableHeadCell}>Statut</TableCell>
            {/* <TableCell className={classes.tableHeadCell}>Commentaires</TableCell> */}
            {/* <TableCell className={classes.tableHeadCell}>Correspondance</TableCell> */}
            <TableCell className={classes.tableHeadCell}>Informations du colis</TableCell>
            <TableCell />
            {/* <TableCell>Total: {countItems}</TableCell> */}
          </TableRow>
        </TableHead>
        {/* <CountCommentsTasksContext.Provider value={myTest}> */}
        <Get
          url={urlTrackingList}
          instance={authAxios}
        >
          {(error, response, isLoading, makeRequest, axios) => {
            if(error) {
              return (<TableBody><TableRow><TableCell>Something bad happened: {error.message} <button onClick={() => makeRequest({ params: { reload: true } })}>Retry</button></TableCell></TableRow></TableBody>)
            } else if(isLoading) {
              return (<TableBody><TableRow><TableCell>Loading...</TableCell></TableRow></TableBody>)
            } else if(response !== null) {
              let test = [];
              return (<TableBody>
                {response.data.results.filter(FILTER_MAP['FilterButtons']).filter(FILTER_MAP['Type']).map(word => {
                    if(customSearch(word).toLowerCase().includes(input ? input.toLowerCase() : '')){
                      test.push(word);
                    }
                    return null;
                })}
                {setCountItems(test.length)}
                {test.sort(SORT_BY_DATE[sortByDate]).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(x => {
                    return <Row key={x.id} item={x} />;
                })}
                {emptyRows > 0 && <TableRow style={{ height: 0 * emptyRows }} />}
              </TableBody>);
            }
            return (<TableBody><TableRow><TableCell>Default message before request is made.</TableCell></TableRow></TableBody>)
          }}
        </Get>
          {/* </CountCommentsTasksContext.Provider> */}
      </Table>
      <TablePagination
        rowsPerPageOptions={[20, 30, 60]}
        colSpan={3}
        component="div"
        count={countItems}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: { 'aria-label': 'rows per page' },
          native: true,
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableContainer>
    </div>
  );
}
